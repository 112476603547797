import ApiService from "../services/api.service";

const prefix = "reconcile";
const rawDownload = "reconcile/download-raw-file";
const diffDownload = "reconcile/download-diff-file";
const reUpload9payFile = "reconcile/reupload-9pay-file";
const uploadFinalFile = "reconcile/upload-final-file";
const listExportRecords = "list-export-records";
const exportDataDetail = "reconcile/export-data-detail";
const downloadExportData = "reconcile/download-export-data";
const vnpayList = "partners/vnpay";
const vnpayExport = "partners/vnpay/export";
const charity = "wallet/charity";
const exportCharity = "wallet/charity/export";
const getProducts = "wallet/charity/products";
const getProviders = "wallet/charity/providers";
const vBeeList = "notice/vbee";
const southTelecom = "notice/south-telecom";
const callLog = "call-logs";
const napasCashout = "wallet/napas/cashout";
const napasCashoutExport = "wallet/napas/cashout/export";
const merchant = "wallet/merchant";
const kyc = "partners/kyc";
const vneid = "partners/vneid";
const transactionFee = "wallet/transaction-fee";
export default {
  /*
        type: uploaded - File đối soát của 9Pay, disputed - File đối soát của Bank
     */
  downloadRawFile(date, bankCode, type = "uploaded") {
    const params = {
      bank_code: bankCode,
      date: date,
      type: type
    };
    return ApiService.post(rawDownload, params);
  },

  downloadDiffFile(date, bankCode) {
    const params = {
      bank_code: bankCode,
      date: date
    };
    return ApiService.post(diffDownload, params);
  },

  reUpload9payFile(date, bankCode) {
    const params = {
      bank_code: bankCode,
      date: date
    };

    return ApiService.post(reUpload9payFile, params);
  },

  uploadFinalFile(formData) {
    return ApiService.post(uploadFinalFile, formData);
  },

  listExportRecords(params) {
    return ApiService.get(prefix, listExportRecords, params);
  },

  exportDataDetail(params) {
    return ApiService.post(exportDataDetail, params);
  },

  downloadExportData(params) {
    return ApiService.post(downloadExportData, params, {
      responseType: "blob"
    });
  },
  getVnpayTransactions(params) {
    return ApiService.get(prefix, vnpayList, params);
  },
  exportVnpayTransactions(params) {
    return ApiService.get(prefix, vnpayExport, params);
  },
  getListCharity(params) {
    return ApiService.get(prefix, charity, params);
  },
  exportCharity(params) {
    return ApiService.get(prefix, exportCharity, params);
  },
  getListProductsCharity(params) {
    return ApiService.get(prefix, getProducts, params);
  },
  getListProvidersCharity(params) {
    return ApiService.get(prefix, getProviders, params);
  },
  getVbeeTransactions(params) {
    return ApiService.get(prefix, vBeeList, params);
  },
  getVbeeBygroup(params) {
    return ApiService.get(prefix, vBeeList + "/group", params);
  },
  exportVbeeTransactions(params) {
    return ApiService.get(prefix, vBeeList + "/export", params);
  },
  getSouthTelecomTransactions(params) {
    return ApiService.get(prefix, southTelecom, params);
  },
  getSouthTelecomBygroup(params) {
    return ApiService.get(prefix, southTelecom + "/group", params);
  },
  exportSouthTelecomTransactions(params) {
    return ApiService.get(prefix, southTelecom + "/export", params);
  },
  getNapasCashOut(params) {
    return ApiService.get(prefix, napasCashout, params);
  },
  exportNapasCashOut(params) {
    return ApiService.get(prefix, napasCashoutExport, params);
  },
  getListOrders(params) {
    return ApiService.get(prefix, merchant, params);
  },
  exportListOrders(params) {
    return ApiService.get(prefix, merchant + "/export", params);
  },
  getListKyc(params) {
    return ApiService.get(prefix, kyc, params);
  },
  exportListKyc(params) {
    return ApiService.get(prefix, kyc + "/export", params);
  },
  getListVneid(params) {
    return ApiService.get(prefix, vneid, params);
  },
  exportListVneid(params) {
    return ApiService.get(prefix, vneid + "/export", params);
  },
  transactionFee(params) {
    return ApiService.get(prefix, transactionFee, params);
  },
  exportTransactionFee(params) {
    return ApiService.get(prefix, transactionFee + "/export", params);
  },
  getCallLogs(params) {
    return ApiService.get(prefix, callLog, params);
  },
  exportCallLog(params) {
    return ApiService.get(prefix, callLog + "/export", params);
  },
};
